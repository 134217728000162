/* @font-face {
  font-family: 'Rubik';
  src: local('Rubik'), url(./fonts/Lato.woff) format('woff');
} */

/* @font-face {
  font-family: "GoldmanBold";
  src: local("GoldmanBold"),
   url("./fonts/Goldman/Goldman-Bold.ttf") format("truetype");
  font-weight: bold;
  } */

@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Rubik", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.lightGalleryWrapper {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-auto-flow: row;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
}

.lightGalleryElement {
  position: relative;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number]{
  -moz-appearance: textfield;
}